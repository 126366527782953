//Controller
import BaseController from "@/controllers/base-controller";

//Standard Components
import GlobalServices from "@/services/global";
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";
import UtilityString from "@/utilities/strings";
import cacheService from "@/services/cache";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import { IController } from "@/interfaces/IController";

import SearchService from "@/services/search";

//Interfaces
import { IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs } from "@/interfaces/UXSidePanel";

import { IUXList, IListData, ITableHeader, ITable, ITableHoverMenu, ITableAction, IEditAction, IRoute } from "@/interfaces/IUXList";
import { IInfo } from "@/interfaces/IUXInfo";

//Services
import { IFilterClient, IFilterItem } from "@/interfaces/IUXFilters";
import store from "@/store";

import moment from "moment";

class PurchaseOrderController implements IController {
  private config = new API_CONFIG();
  private auth = AuthService.getInstance();
  private utility = new UtilityString();
  private app = new ApplicationService();
  private global = new GlobalServices();
  private search = new SearchService();

  _actionId = MODULE.PURCHASEORDER.ActionId;
  _moduleId = MODULE.PURCHASEORDER.ModuleId;

  //Standard application event types, to standardize event logs and modal events
  EVENT_ACTION = EVENTS;

  //Stores the full data object state of the side panel to be returned.
  _sidepanel: IUXSidePanel = {} as IUXSidePanel;

  //Stores the default action, if the side panel action button is clicked without choosing an option from the list
  _defaultAction: IButtonAction = {} as IButtonAction;

  //The data object being passed in, eg.. Project, Client , Quote Data etc..
  _data = null;

  //The data object being passed in, edit details
  _details = null;

  //Standard functionality such as print, email, close are stored in the base controller
  _base = new BaseController();

  //Reference to project earth for backward compability
  earth = new EarthApplicationService();

  // Static caches to hold info list and header after the first call.
  private static _cachedInfoList: IInfo | null = null;
  private static _cachedHeader: ISidePanelHeader | null = null;

  //Constructor Takes in a Module Data Object (Project, Client, Invoice)
  constructor() {}

  //Page Header / Title
  getTitle(): String {
    let title = this._base.getTitle("Purchase Order", this._data.referenceNumber, this._data.status);
    return title;
  }

  getSubTitle() {
    let subtitle = this._base.getSubTitle(this._data.name, this._data.location);
    return subtitle;
  }

  //Generate Drop Down Menu for Action Items
  getDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Purchase Order
    let purchaseOrderUrl = this.earth.getNewPurchaseOrderNavigationUrl(this._data.intProjectID);
    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.PURCHASEORDER.New,
      menuTitle: "Create a Purchase Order",
      modalTitle: "Create New Purchase Order",
      saveButtonTitle: "Create Purchase Order",
      data: this._data,
      componentName: "",
      url: purchaseOrderUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Invoice
    let invoiceAction = {
      id: 2,
      eventType: this.EVENT_ACTION.INVOICE.New,
      menuTitle: "Create an Invoice",
      modalTitle: "Create New Invoice",
      componentName: "NewInvoice",
      saveButtonTitle: "Create Invoice",
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    //Set Default Action
    this._defaultAction = invoiceAction;
    actions.push(invoiceAction);

    //Quote
    let quoteUrl = this.earth.getNewQuoteNavigationUrl(this._data.intProjectID);
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.INVOICE.New,
      menuTitle: "Create a Quote",
      modalTitle: "Create New Quote",
      saveButtonTitle: "Create Quote",
      data: this._data,
      componentName: "",
      url: quoteUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Old Work Order Screen
    let pageTitle = this.getTitle();
    let earthUrl = this.earth.getWorkOrderDetailsUrl(this._data.intProjectID, pageTitle);
    actions.push({
      id: 4,
      eventType: this.EVENT_ACTION.PROJECTS.ViewOld,
      menuTitle: "View Old Work Order",
      modalTitle: "Old Work Order Page",
      saveButtonTitle: "Old Work Order",
      data: this._data,
      componentName: "",
      url: earthUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    return actions;
  }

  //SECTION - Header
  getHeader(): ISidePanelHeader {
    // Use static cache after first call.
    if (PurchaseOrderController._cachedHeader) {
      return PurchaseOrderController._cachedHeader;
    }

    let headerDataObj: ISidePanelHeader = {} as ISidePanelHeader;

    //Hide/Show Print Preview Button
    headerDataObj.isPreviewEnabled = true;

    //Hide/Show Email Button
    headerDataObj.isEmailEnabled = true;

    //Hide/Show Action Button
    headerDataObj.isActionButtonEnabled = false;

    headerDataObj.titleSection = {} as ILabelTitle;

    //Title Section (Left)
    headerDataObj.titleSection.headTitle = this.getTitle();

    //Sub Title
    headerDataObj.titleSection.subTitle = this.getSubTitle();

    //Action Button
    headerDataObj.actions = this.getDropDownMenu();
    headerDataObj.defaultAction = this._defaultAction;

    //Print Button
    if (headerDataObj.isPreviewEnabled) {
      headerDataObj.print = this._base.getPrintAction(this._data.id, this._moduleId);
    }

    //Email Button
    if (headerDataObj.isEmailEnabled) {
      headerDataObj.email = this._base.getEmailAction(this._data, this._moduleId, this._actionId);
    }

    // Cache the header for future calls.
    PurchaseOrderController._cachedHeader = headerDataObj;
    return headerDataObj;
  }

  //SECTION - Action Bar
  getActionBar(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Edit Client
    let clientUrl = this.earth.getViewClientNavigationUrl(this._data.intClientID, this._data.businessName);
    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.CLIENTS.View,
      menuTitle: "Edit Client",
      modalTitle: "Editing Client",
      saveButtonTitle: "Save Changes",
      data: this._data,
      componentName: "",
      url: clientUrl,
      icon: "fa fa-pencil",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Map
    let mapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this._data.location);
    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.CLIENTS.ExternalView,
      menuTitle: "Map",
      modalTitle: "",
      saveButtonTitle: "",
      data: this._data,
      componentName: "",
      url: mapUrl,
      icon: "fa fa-map-marker",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Dispatch
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.DISPATCH.New,
      menuTitle: "Dispatch",
      modalTitle: "Dispatch to Technician",
      saveButtonTitle: "Send Message",
      data: this._data,
      componentName: "NewDispatch",
      url: "",
      icon: "fa fa-truck",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Sales Quote Navigation (if applicable)
    if (this._data.intSalesQuotationID > 0) {
      let quoteUrl = this.earth.getViewQuoteDetailsUrl(this._data.intSalesQuotationID);
      actions.push({
        id: 4,
        eventType: this.EVENT_ACTION.CLIENTS.View,
        menuTitle: "View Quote",
        modalTitle: "Editing Quote",
        saveButtonTitle: "Save Changes",
        data: this._data,
        componentName: "",
        url: quoteUrl,
        icon: "fa fa-file-text-o",
        UI_TYPE: APP.UI_TYPE.NewTab,
      });
    }

    return actions;
  }

  //SECTION - Info Summary - Read only
  getInfoSectionDetails(): ISidePanelInfoSection {
    const infoSidepanelDetail = this._sidepanel.details as any;
    let infosection: ISidePanelInfoSection = {
      leftSection: {
        title: "Delivery Date: " + moment(infoSidepanelDetail.DeliveryPickupDate).format("DD-MMM-YYYY"),
        labels: [
          {
            id: 0,
            title: "Confirmation Date: ",
            labelValue: infoSidepanelDetail.ConfirmationDate ? moment(infoSidepanelDetail.ConfirmationDate).format("DD-MMM-YYYY") : "-",
          },
          {
            id: 1,
            title: "Status: ",
            labelValue: infoSidepanelDetail.Status,
          },
        ],
      },
      rightSection: {
        title: "Delivery Preference: " + infoSidepanelDetail.DeliveryOptions,
        labels: [
          {
            id: 1,
            title: "Created: ",
            labelValue: moment(infoSidepanelDetail.CreatedDate).format("DD-MMM-YYYY"),
          },
          { id: 2, title: "Created By: ", labelValue: infoSidepanelDetail.CreatedBy },
        ],
      },
    };
    return infosection;
  }

  //SECTION - Tabs
  getTabSection(): ISidePanelTabs[] {
    let tabs: ISidePanelTabs[] = [];

    // Example: Materials Tab
    let servicesTab = {
      id: 1,
      title: "Materials",
      actionButton: this.getActionButton(MODULE.MATERIALS, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.MATERIALS, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        id: this._data.id,
        parentActionId: MODULE.PURCHASEORDER.ActionId,
        actionId: MODULE.MATERIALS.ActionId,
        moduleId: MODULE.PURCHASEORDER.ModuleId,
      },
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);
        return { uxInventory };
      },
    };

    tabs.push(servicesTab);

    // Notes Tab
    let notesTab = {
      id: 2,
      title: "Notes",
      actionButton: null,
      componentName: "NotesUniversal",
      componentPath: "@/components/_universal/notes-universal",
      param: {
        id: this._data.id,
        parentActionId: MODULE.PURCHASEORDER.ActionId,
        actionId: MODULE.NOTES.ActionId,
      },
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    tabs.push(notesTab);

    // Documents/Photos Tab
    let documentsTab = {
      id: 3,
      title: "Documents/Photos",
      actionButton: this.getActionButton(MODULE.DOCUMENTS, "new", APP.UI_TYPE.Modal),
      componentName: "UniversalDocuments",
      componentPath: "@/components/_universal/tab-documents",
      param: {
        id: this._data.id,
        parentActionId: MODULE.PURCHASEORDER.ActionId,
        actionId: MODULE.DOCUMENTS.ActionId,
      },
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    tabs.push(documentsTab);

    // History Tab
    let historyTab = {
      id: 4,
      title: "History",
      actionButton: null,
      componentName: "Activity",
      componentPath: "@/components/activity/activity-history",
      param: {
        id: this._data.id,
        parentActionId: MODULE.PURCHASEORDER.ActionId,
        actionId: MODULE.NOTES.ActionId,
        parentId: this._data.id,
      },
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    tabs.push(historyTab);

    return tabs;
  }

  //Dynamically Create Action Button Properties
  getActionButton(module, actionType, UI_MODAL_TYPE) {
    let title = "";
    let componentName = "";
    var eventType: any = this.EVENT_ACTION.DOCUMENTS;

    //Set Documents Tab Details
    if (module === MODULE.DOCUMENTS) {
      eventType = this.EVENT_ACTION.DOCUMENTS_PHOTOS;
      if (actionType === "new") {
        title = "Add Documents/Photos";
        componentName = this.EVENT_ACTION.DOCUMENTS_PHOTOS.New;
      } else if (actionType === "edit") {
        title = "Preview Document/Photo";
        componentName = this.EVENT_ACTION.IFRAME.PreviewImage;
      }
    }
    //Set Materials Tab Details
    if (module === MODULE.MATERIALS) {
      eventType = this.EVENT_ACTION.SERVICES_MATERIALS;
      if (actionType === "new") {
        title = "Add Materials";
        componentName = "NewMaterial";
      } else if (actionType === "edit") {
        title = "Edit Materials";
        componentName = "NewMaterial";
      }
    }

    if (actionType === "new") {
      return {
        id: 1,
        actionId: module.ActionId,
        parentActionId: this._actionId,
        eventType: eventType.New,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null,
        moduleId: this._moduleId,
      };
    } else if (actionType === "edit") {
      return {
        id: 2,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: true,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null,
      };
    }
  }

  getListHeaders(): ITableHeader[] {
    let header: ITableHeader[] = [];

    header.push({
      id: 0,
      title: "P.O #",
      columnName: "referenceNumber",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 1,
      title: "Status",
      columnName: "htmlBadge1",
      isSortable: false,
      isVisible: true,
      isHtmlColumn: true,
    });

    header.push({
      id: 2,
      title: "Supplier",
      columnName: "name",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 3,
      title: "Job Site",
      columnName: "jobSiteDisplayName",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 4,
      title: "Total",
      columnName: "total",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 6,
      title: "Created By",
      columnName: "createdBy",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 7,
      title: "Created Date",
      columnName: "createdDate",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    return header;
  }

  getHoverMenu(): ITableHoverMenu {
    let hover = {} as ITableHoverMenu;
    hover.isEnabled = false;
    return hover;
  }

  getActions(): ITableAction {
    let actions = {} as ITableAction;
    actions.isEnabled = false;
    return actions;
  }

  //Info stats returned at the top of every list page.
 async getInfoList(): Promise<IInfo> {
  // Define a unique cache key for the info list.
  const cacheKey: string = "getInfoList";

  // Use CacheService to either retrieve the cached info or fetch new data
  return await cacheService.fetchWithCache<IInfo>(
    cacheKey,
    async () => {
      // Create the IInfo object and initialize default properties.
      let info: IInfo = {
        isEnabled: false,      // Not implemented for now
        isDetailsView: false,  // Ensure required property is set (if applicable)
        data: []               // Initialize with an empty data array
      };

      // Define the filter for the API call.
      let filter: { actionId: number } = {
        actionId: this._actionId
      };

      // Fetch the results from the API.
      let results = await this.search.getResults("global/list/info", filter);

      // Update the info object with the fetched resources.
      info.data = results.resources;

      // Return the populated info object.
      return info;
    },
    900000 // 15 minutes in milliseconds
  );
}

  //Dynamically determin the filters for each list page...
  async getFilters(): Promise<IFilterClient> {
    let filter = {} as IFilterClient;
    let filters: IFilterItem[] = [];

    filter.hasAdvancedFilter = false;
    filter.isEnabled = false;

    filters.push({
      id: 0,
      title: "Purchase Order/Job #",
      name: "po.purchaseref",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "po.purchaseref",
      isfilterByDisplayName: true,
    });

    filters.push({
      id: 1,
      title: "Supplier/Job Site",
      name: "businessname",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "s.businessname",
      isfilterByDisplayName: true,
    });

    filters.push({
      id: 2,
      title: "Status",
      name: "status",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.PURCHASEORDER.UX.Status),
      selectedValue: "",
      defaultValue: "",
      query: "IntPurchaseOrderStatusID",
      isfilterByDisplayName: false,
    });

    filters.push({
      id: 3,
      title: "Delivery Preference",
      name: "createdby",
      uxtype: "dropdown",
      data: await this.global.getUXDropDownByColumnName(MODULE.PURCHASEORDER.ActionId, "deliveryoptions"),
      selectedValue: 1,
      defaultValue: 1,
      query: "po.deliveryoptions",
      isfilterByDisplayName: true,
    });

    filters.push({
      id: 4,
      title: "Created By",
      name: "createdby",
      uxtype: "dropdown",
      data: await this.global.getUXDropDownByColumnName(MODULE.PURCHASEORDER.ActionId, "createdBy"),
      selectedValue: 1,
      defaultValue: 1,
      query: "po.createdBy",
      isfilterByGuid: true,
    });

    filter.filters = filters;
    return filter;
  }

  async getListResults(filter) {
    filter.actionId = this._actionId;
    return await this.search.getResults("global/list", filter);
  }

  //Call back function - to Load UX Dropdown List
  async loadDropDownList() {
    this._sidepanel.uxdata = async (pageName: string): Promise<Object> => {
      if (pageName == APP.PAGE.PurchaseOrder.Details) {
        let uxSupplier = await this.global.getUXDropDown(MODULE.SUPPLIER.ActionId);
        let uxDeliveryPreferenceList = await this.global.getUXDropDown(MODULE.PURCHASEORDER.UX.DeliveryPreference);
        let uxStatus = await this.global.getUXDropDown(MODULE.PURCHASEORDER.UX.Status);
        return { uxSupplier, uxDeliveryPreferenceList, uxStatus };
      }
    };
  }

  //LIST - Entry for list page
  async list(): Promise<IUXList> {
    let list: IUXList = {} as IUXList;
    list.actionId = this._actionId;
    list.settings = { isHoverOverRowEnabled: true };

    list.table = {} as ITable;
    list.table.header = this.getListHeaders();

    list.table.data = await this.getListResults({});

    // Use static cached info for list.info.
    list.info = await this.getInfoList();

    list.isSidePanelFrame = false;
    list.filter = await this.getFilters();

    list.table.hover = {} as ITableHoverMenu;
    list.table.hover = this.getHoverMenu();

    list.table.actions = {} as ITableAction;
    list.table.actions = this.getActions();

    list.editAction = {} as IEditAction;
    list.editAction.route = {} as IRoute;
    list.editAction.route = {
      name: "purchase-order-details",
      path: "/purchase-orders/details",
      param: { id: 0 },
    };

    return list;
  }

  //MAIN - Entry for Details page
  async main(data): Promise<IUXSidePanel> {
    this._data = data;
    this._data.typeId = MODULE.NOTES.PURCHASE_ORDER;
    this._sidepanel = store.getters.getSidePanel;
    this._sidepanel.actionId = this._actionId;
    this._sidepanel.pageName = APP.PAGE.PurchaseOrder.Details;

    // Use static cached header if available.
    this._sidepanel.headerDataObj = this.getHeader();

    if (this._sidepanel.isSidePanelFrame) {
      this._sidepanel.url = this.earth.getPurchaseOrderDetailsUrlV2(this._data);
    } else {
      const detail = await this.global.getEditDetails(this._actionId, data.id);
      this._sidepanel.details = detail;
      this._details = detail;
      this._sidepanel.actionBarDataObj = this.getActionBar();
      this._sidepanel.infoSectionDataObj = this.getInfoSectionDetails();
      this._sidepanel.info = { isEnabled: true, data: [], isDetailsView: true };
      this._sidepanel.iFrame = this._base.getIFrameDetails(this._data, this._data.id);
      this._sidepanel.moduleId = this._moduleId;
      this._sidepanel.tabSectionDataObj = this.getTabSection();
      this.loadDropDownList();
    }

    return this._sidepanel;
  }
}

export default PurchaseOrderController;
